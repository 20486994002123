.container {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    position: relative;
    top: 2px;
    animation: bounce 0.3s ease infinite alternate;
    letter-spacing: 1px;
    font-size: var(--loading-font-size, 2rem);
  }

  span:nth-child(1) {
    animation-delay: 0.1s;
  }

  span:nth-child(2) {
    animation-delay: 0.1s;
  }

  span:nth-child(3) {
    animation-delay: 0.1s;
  }

  span:nth-child(4) {
    animation-delay: 0.1s;
  }

  span:nth-child(5) {
    animation-delay: 0.1s;
  }

  span:nth-child(6) {
    animation-delay: 0.1s;
  }

  span:nth-child(7) {
    animation-delay: 0.1s;
  }

  span:nth-child(8) {
    animation-delay: 0.1s;
  }

  span:nth-child(9) {
    animation-delay: 0.1s;
  }

  span:nth-child(10) {
    animation-delay: 0.1s;
  }

  span:nth-child(11) {
    animation-delay: 0.15s;
  }

  span:nth-child(12) {
    animation-delay: 0.2s;
  }

  span:nth-child(13) {
    animation-delay: 0.25s;
  }
}

@keyframes bounce {
  100% {
    top: -2px;
  }
}