.header {
  width: 100%;
  background-color: var(--header);
  // padding: 0px 0 0px;
  padding: 0.5rem 2rem 0.5rem 0;
  position: sticky;
  top: 0;
  z-index: 30;

  .content {
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    align-items: center;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    transition: all 0.9s;

    .menuLeft {
      display: flex;
      align-items: center;
      gap: 2rem;

      .logoMenu {
        padding: 1rem;
        width: 250px;
        text-align: center;

        a {
          img {
            // @include imgw;
            max-width: 100%;
            max-height: 33px;
            cursor: pointer;
          }
        }
      }

      .mainMenu {
        position: relative;
        display: var(--main-menu-display, flex);
        align-items: center;
        margin-top: -2px;

        .menuItemGrid {
          border-radius: 5px;
          transition: all 0.4s;
          position: relative;
          list-style: none;
          width: 4rem;

          svg {
            color: var(--white);
            cursor: pointer;
            font-size: 2.5rem;

            transition: all 0.35s ease;

            &:hover {
              color: var(--yellow-color);
            }
          }

          &:hover {
            .subMenuGrid {
              .grid {
                display: grid;
              }
            }
          }

          .subMenuGrid {
            background-color: var(--primary-color);
            color: white;

            width: auto;
            position: absolute;
            top: 2rem;
            left: 0;
            margin-top: 5px;
            padding-top: 10px;
            z-index: 5;
            height: 1rem;

            .grid {
              display: none;
              grid-template-columns: repeat(3, 1fr);
              gap: 4rem 3rem;
              background: var(--body-color);
              padding: 3rem 2rem;
              width: 58rem;

              &:hover {
                display: grid;
              }

              .subMenuGridItem {
                display: flex;
                height: 2rem;
                width: 2rem;

                img {
                  height: 100%;
                  width: 100%;
                }

                a,
                .link {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  font-weight: normal;
                  font-size: 1.3rem;
                  white-space: nowrap;

                  border-radius: 0.5rem;
                  cursor: pointer;
                  padding: 1rem;

                  //width: 100%;
                  width: fit-content;
                  transition: all 0.35s ease;

                  svg {
                    color: transparent;
                    font-size: 3rem;
                  }

                  &:hover {
                    background-color: var(--secondary-color);

                    svg {
                      color: var(--yellow-color);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .menuLinks {
      display: flex;
      justify-content: center;
      gap: 2rem;

      .active {
        border-radius: 0;
        border-bottom: var(--yellow-color) 1px solid;
        color: var(--yellow-color);

        a,
        .link {
          color: var(--yellow-color);
        }
      }

      div {
        border-radius: 5px;
        transition: all 0.4s;
        position: relative;
        list-style: none;
        border-bottom: 1px solid transparent;

        &:hover {
          border-bottom: 1px solid var(--yellow-color);
          border-radius: 0.5rem 0.5rem 0 0;
          color: var(--yellow-color) 8;
        }

        &:not(:last-child) {
          margin-right: 20px;
        }

        //   &.active {
        //     background: var(--dark-blue);
        //     color: #fff;
        //   }

        a,
        .link {
          color: var(--white);
          font-weight: 600;
          font-size: 16px;
          transition: all 0.3s;
          //font-family: gulim, cantarell, sans-serif;
          text-transform: capitalize;
          display: flex;
          align-items: center;
          gap: 9px;
          cursor: pointer;
          user-select: none;

          // &::before {
          //     position: absolute;
          //     top: 100%;
          //     left: 0;
          //     width: 100%;
          //     height: 1px;
          //     background: var(--white);
          //     content: "";
          //     opacity: 0;
          //     transition: all 0.4s;
          // }

          // &.active,
          // &:hover {
          //     &::before {
          //         opacity: 1;
          //     }
          // }

          .icon {
            margin-top: -3px;
          }
        }

        &:hover {
          .sub-menu {
            opacity: 1;
            visibility: visible;
            transform: scale(1);
          }
        }
      }
    }

    .dashboarWrap {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: flex-end;

      button {
        background-color: transparent;
        border: 0;

        color: var(--yellow-color);
        cursor: pointer;
        font-size: 1.5rem;
      }

      .items {
        text-align: right;

        .small {
          font-size: 10px;
          color: var(--white);
          margin-bottom: 4px;
          display: block;
        }

        h6 {
          font-weight: 600;
          color: var(--white);
          font-size: 14px;
          line-height: 110%;
        }

        .button {
          padding: 8px 20px 10px;
          font-size: 14px;
          font-weight: 600;
          display: inline-block;
          text-transform: capitalize;
          transition: all 0.3s;
          text-align: center;
          background: var(--button-one);
          position: relative;
          overflow: hidden;
          border-radius: 10px;
          z-index: 1;

          &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            height: 100%;
            width: 0px;
            transition: all 0.4s;
          }

          &:hover::before {
            width: 100%;
          }

          span {
            z-index: 1;
            position: relative;
            transition: all 0.4s;
          }

          &::before {
            background: linear-gradient(114.26deg, #ff6600 13.41%, #ff6600 87.24%);
          }
        }

        .customDropdown {
          .customDropdownUser {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            text-align: center;
            line-height: 33px;
            background: var(--social-box);

            .icons {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              width: 30px;
              height: 30px;
              border-radius: 50%;
              text-align: center;
              line-height: 33px;
              background: var(--social-box);
              position: relative;

              svg {
                color: var(--white);
                font-size: 2rem;
              }
            }
          }
        }
      }

      .dashboarSocial {
        display: flex;
        align-items: center;
        gap: 10px;

        .icons {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          width: 30px;
          height: 30px;
          border-radius: 50%;
          text-align: center;
          line-height: 33px;
          background: var(--social-box);
          position: relative;

          svg {
            color: #1877f2;
            font-size: 2rem;
          }

          .count {
            width: 10px;
            height: 10px;
            text-align: center;
            line-height: 10px;
            background: var(--button-one);
            color: var(--white);
            display: block;
            border-radius: 50%;
            font-size: 8px;
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
    }

    .menuBtnGrp {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      // gap: 1.3rem;

      .languageWrap {
        position: relative;
        .nice-select,
        .selector {
          button {
            color: #fff;
            // padding: 7px 34px 9px 39px;
            &::after {
              border-bottom: 1px solid #fff;
              border-right: 1px solid #fff;
              height: 10px;
              margin-top: -6px;
              pointer-events: none;
              position: absolute;
              right: 16px;
              bottom: 50%;
              width: 10px;
              font-size: 14px;
            }
          }
          ul {
            background-color: rgba(245, 244, 255, 0.229);

            li {
              span {
                color: #fff;
                font-size: 14px;
              }
            }
          }
        }

        .flag {
          position: absolute;
          top: 4px;
          left: 0;
          width: 28px;
          height: 20px;
        }
      }

      button {
        position: relative;
        z-index: 1;

        display: inline-block;

        background: transparent;
        border: 0;
        border-radius: 1rem;

        color: var(--color-button);
        cursor: pointer;

        font-size: 1.4rem;
        font-weight: 600;

        overflow: hidden;
        padding: 9px 40px 12px;

        text-transform: capitalize;
        transition: all 0.3s;
        text-align: center;

        &::before {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          height: 100%;
          width: 0px;
          transition: all 0.4s;
        }

        &:hover::before {
          width: 100%;
        }

        // &:hover {
        //     span {
        //     }
        // }

        span {
          z-index: 1;
          position: relative;
          transition: all 0.4s;
          display: block;
        }
      }

      .buttonNavigation {
        color: var(--yellow-color);
        padding: 0 1.5rem 0 0;

        // &::before {
        //     background: linear-gradient(114.26deg, #ff6600 13.41%, #ff6600 87.24%);
        // }
      }

      .buttonNavigationTow {
        background: var(--yellow-color);

        // &::before {
        //     background: linear-gradient(114.26deg, #f3486a 13.41%, #f3486a 87.24%);
        // }
      }
    }

    .languageWrap {
      position: relative;
      .nice-select,
      .selector {
        button {
          color: #fff;
          // padding: 7px 34px 9px 39px;
          &::after {
            border-bottom: 1px solid #fff;
            border-right: 1px solid #fff;
            height: 10px;
            margin-top: -6px;
            pointer-events: none;
            position: absolute;
            right: 16px;
            bottom: 50%;
            width: 10px;
            font-size: 14px;
          }
        }
        ul {
          background-color: rgba(245, 244, 255, 0.229);

          li {
            span {
              color: #fff;
              font-size: 14px;
            }
          }
        }
      }

      .flag {
        position: absolute;
        top: 4px;
        left: 0;
        width: 28px;
        height: 20px;
      }
    }
  }
}

.headerMobile {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .header {
    display: none;
  }

  .headerMobile {
    display: block;
    width: 100%;
    background-color: var(--header);
    padding: 1.5rem 1rem;
    position: sticky;
    top: 0;
    z-index: 99997;

    .loginHeaderButtons {
      display: flex;
      gap: 0.5rem;
      width: 100%;

      .buttonNavigation {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        padding: 1rem 0;
        width: 100%;
        background-color: transparent;
        color: var(--yellow-color);
        border: 1px var(--yellow-color) solid;
        border-radius: 0.3rem;
      }

      .buttonNavigationTow {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        padding: 1rem 0;
        width: 100%;
        background-color: var(--yellow-color);
        color: var(--white);
        border: 1px var(--yellow-color) solid;
        border-radius: 0.3rem;
      }
    }

    .contentMobile {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logoMenu {
        .logo {
          img {
            max-width: 15rem;
            max-height: 33px;
            cursor: pointer;
          }
        }
      }

      .menuBtnGrp {
        display: flex;
        align-items: center;
        gap: 1rem;

        .languageWrap {
          position: relative;

          .flag {
            position: absolute;
            top: 4px;
            left: 0;
            width: 28px;
            height: 20px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .dashboarWrap {
            display: flex;
            gap: 20px;
            align-items: center;

            .dashboarSocial {
              display: flex;
              align-items: center;
              gap: 10px;

              .icons {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                width: 30px;
                height: 30px;
                border-radius: 50%;
                text-align: center;
                line-height: 33px;
                background: var(--social-box);
                position: relative;

                svg {
                  color: #1877f2;
                  font-size: 2rem;
                }

                .count {
                  width: 10px;
                  height: 10px;
                  text-align: center;
                  line-height: 10px;
                  background: var(--button-one);
                  color: var(--white);
                  display: block;
                  border-radius: 50%;
                  font-size: 8px;
                  position: absolute;
                  top: 0;
                  right: 0;
                }
              }
            }
          }
        }

        svg {
          color: white;
          font-size: 3rem;
        }
      }
    }

    .mainMenuMobile {
      position: absolute;
      // top: 100%;
      right: 0;
      left: 0;
      z-index: 7;

      background: var(--header-color);
      background-color: var(--header) !important;
      border-radius: 0 0 1rem 1rem;

      // overflow-y: auto;
      padding: 5px 5px;
      max-height: calc(100vh - 130px);

      transform-origin: top;
      transition: all 0.4s !important;

      li {
        background: transparent;
        border-bottom: 1px solid rgba(136, 182, 255, 0.3764705882);
        border-radius: 5px;

        // padding-left: 10px;

        a,
        .link {
          color: var(--white);
          font-weight: 600;
          font-size: 16px;
          transition: all 0.3s;
          font-family: "Inter", sans-serif;
          text-transform: capitalize;
          display: flex;
          align-items: center;
          gap: 9px;
          padding: 10px 12px 11px;
          font-size: var(--font-size-md);
        }
      }

      .btns {
        display: flex;
        align-items: center;
        gap: 1rem;

        margin-top: 1rem;

        button {
          position: relative;
          z-index: 1;

          display: inline-block;

          background: var(--yellow-color);
          border: 0;
          border-radius: 1rem;

          color: var(--color-button);
          cursor: pointer;

          font-size: 1.4rem;
          font-weight: 600;

          overflow: hidden;
          padding: 6px 25px 8px;

          text-transform: capitalize;
          transition: all 0.3s;
          text-align: center;

          &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            height: 100%;
            width: 0px;
            transition: all 0.4s;
          }

          &:hover::before {
            width: 100%;
          }

          span {
            z-index: 1;
            position: relative;
            transition: all 0.4s;
            display: block;
          }
        }

        // .buttonNavigation {
        //     background: var(--button-one);

        //     &::before {
        //         background: linear-gradient(114.26deg, #ff6600 13.41%, #ff6600 87.24%);
        //     }
        // }

        // .buttonNavigationTow {
        //     background: var(--button-two);

        //     &::before {
        //         background: linear-gradient(114.26deg, #f3486a 13.41%, #f3486a 87.24%);
        //     }
        // }
      }
      .dashboarWrap {
        display: flex;
        gap: 20px;
        align-items: center;
        margin-top: 0.5rem;

        button {
          display: inline-block;

          background: var(--yellow-color);
          border: 0;
          border-radius: 1rem;

          color: var(--color-button);
          font-size: 1.4rem;
          font-weight: 600;

          padding: 0.8rem 2rem;
          text-transform: capitalize;
          transition: all 0.3s;
          text-align: center;
          font-size: var(--font-size-md);
        }

        .items {
          text-align: right;

          .small {
            font-size: 10px;
            color: var(--white);
            margin-bottom: 4px;
            display: block;
          }

          h6 {
            font-weight: 600;
            color: var(--white);
            font-size: 14px;
            line-height: 110%;
          }

          .button {
            padding: 8px 20px 10px;
            font-size: 14px;
            font-weight: 600;
            display: inline-block;
            text-transform: capitalize;
            transition: all 0.3s;
            text-align: center;
            background: var(--button-one);
            position: relative;
            overflow: hidden;
            border-radius: 10px;
            z-index: 1;

            &::before {
              position: absolute;
              content: "";
              top: 0;
              left: 0;
              height: 100%;
              width: 0px;
              transition: all 0.4s;
            }

            &:hover::before {
              width: 100%;
            }

            span {
              z-index: 1;
              position: relative;
              transition: all 0.4s;
            }

            &::before {
              background: linear-gradient(114.26deg, #ff6600 13.41%, #ff6600 87.24%);
            }
          }

          .customDropdown {
            .customDropdownUser {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              text-align: center;
              line-height: 33px;
              background: var(--social-box);

              .icons {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                width: 30px;
                height: 30px;
                border-radius: 50%;
                text-align: center;
                line-height: 33px;
                background: var(--social-box);
                position: relative;

                svg {
                  color: var(--white);
                  font-size: 2rem;
                }
              }
            }
          }
        }

        .selectMobile {
          position: relative;

          .flag {
            position: absolute;
            top: 4px;
            left: 0;
            width: 28px;
            height: 20px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
