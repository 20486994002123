.navigationSports {
  .navigationGrid {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 0.2rem;

    .subMenuGridItem {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: var(--secondary-color);
      padding: 1rem 0 1rem 1rem;
      cursor: pointer;
      font-size: 1.1rem;

      img {
        height: 1.5rem;
        width: 1.5rem;
      }

      .titleActive {
        color: var(--yellow-color);
      }

      &:not(.firstRow):hover{
        background: var(--primary-color);
      }
    }

    .firstRow {
      background-color: var(--yellow-color);
      background-image: var(--navigation-sports-gradient);

      &:hover{
        opacity: 0.8;
      }
    }
  }

  > :last-child {
    border-bottom: 1px solid white;
  }
}

@media (max-width: 1500px) {
  .navigationSports {
    // max-height: calc(100vh - 200px);
    overflow-x: auto;
  }
}

@media only screen and (max-width: 1024px) {
  .navigationSports {
    display: none;
  }

  .navigationSportsMobile {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;

    background-color: var(--body-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100000;
    overflow-x: auto;

    .container {
      position: relative;

      display: flex;
      flex-direction: column;

      // padding: 3rem;
      width: 100%;
      //   height: 80%;
      height: 100%;

      .content {
        overflow-x: auto;
        padding-bottom: 28rem;

        .child {
          //background-color: red;
        }
      }
    }
  }
}
