.notificationPopup{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    gap: 2rem;

    .container, .containerMessage{
        height: 25rem;
        min-width: 35rem;
        // width: 35rem;
        background: black;
        // position: absolute;
        
        padding: 1rem;

        .content{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            color: white;
            gap: 2rem;
    
            .popupHeader{
                .titleArea{
                    width: 100%;
                    display: flex;
                    gap: 1rem;
                    font-size: 2rem;
                    align-items: center;
                    justify-content: center;
    
                    >svg{
                        color: red;
                    }
    
                    >span{
                        font-weight: 600;
                        width: 100%;
                    }
                }
            }
    
            .popupBody{
                // height: 100%;
                margin: auto;
                // align-self: center;
    
                .bodyContent{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 2rem;
    
                    >svg{
                        color: #49b6d6;
                        font-size: 5rem;
                    }

                    >span{
                        font-weight: 600;
                        font-size: 1.7rem;
                    }
                }
            }
        }
    }
}
