.accordionSport {
    background-color: var(--navigation-sports-item-color);
    border-radius: 0.5rem;

    // margin: .5rem 0;

    .accordionTitleSport {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;

        border-bottom: 1px solid var(--multiborder);

        color: white;
        cursor: pointer;

        font-size: 1.2rem;

        padding: 0.8rem 1rem;

        .rigth {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            img {
                width: 24px;
                height: auto;
            }

            svg {
                color: white;
                font-size: 1.5rem;
            }
        }

        p {
            color: var(--navigation-sports-game-number-color);
        }

        &:hover{
            background: var(--primary-color);
        }
    }

    .accordionLine {
        border-bottom: 1px solid white;
        width: 100%;
    }
}
