.content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.8rem 1rem;
  gap: 2rem;
  cursor: pointer;
  border-bottom: 1px solid var(--white);
  background: var(--hightlight-background);
  border-color: var(--hightlight-border);

  .sportIcon {
    img {
      width: auto;
      height: 2.5rem;
    }
  }

  .leagueIcon {
    img {
      width: auto;
      height: 2.5rem;
    }
  }
  .leagueName {
    width: 100%;
    color: var(--white);
    font-size: var(--font-size-md);
  }

  &:hover{
    background: var(--secondary-color);
  }
}
