/* Google Font */
//@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
// @import "tailwindcss/base";
// @import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "rsuite/Calendar/styles/index.css";

// @import 'react-big-calendar/lib/sass/styles';

@font-face {
  font-family: "gulim", Comfortaa, sans-serif;
  src: url("/assets/font/gulim.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: pcp;
  src: url("/assets/font/pcp.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

* {
  scrollbar-width: none;
  //scrollbar-color: rgba(155, 155, 155, 0.5) transparent; /* Firefox *//* Chrome/Safari/Webkit */

  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
    border: transparent;
  }
}

:root {
  // --body-color: #060c1f;
  // --slidebox: #141c33;
  // --header: #202a39;

  --primary-color: #1b1b1b;
  --secondary-color: #2d2d2d;
  --background-footer: black;

  --black-500: #0b0d10;
  --background-table: #101216;
  --body-color: #1b1b1b;
  --border-color-input: #614f25;
  --black: #000;

  --color-button: #000;

  --gray-100: #2f343a;

  --white: #fff;
  --yellow-color: #fdb82c;

  --header: #1b1b1b;

  // --slidebox: #0a1726;
  --slidebox: #1b1b1b;

  --active-color: #09ff8d;
  --active-two: #09ff8cb3;
  --theme-one: #88b6ff;
  --theme-two: #1877f2;
  --button-one: #f3486a;
  --button-two: #ff6600;
  --subheader: #161f2c;
  --startable: #fa5500;
  --social-box: #283968;
  --textcolor: #8a92ab;
  --pointbox: #283352;
  --betslip: #636b82;
  --multiborder: #7a8899;
  --signbet: #20283f;
  --signborder: #526271;
  --leftpreborder: #4c546a;
  --preactive: #0f62e6;
  --place: #858b9d;
  --paycheck: #40507c;
  --white-color: #fff;
  --price-color: #aa8200;
  --hover-color: #393939;

  --loading-font-size: 1.2rem;
  --select-background-color: #2d2d2d;
  --border-color: #f3ba2f;
  --select-color: #fff;
  --select-placeholder: #fff;

  --font-size-lg: 1.4rem;
  --font-size-md: 1.2rem;
  --font-size-sm: 0.5rem;

  --alt-header-background: var(--yellow-color);
  --alt-header-border: var(--yellow-color) 1px solid;
  --alt-header-hover-background: linear-gradient(180deg, #695d00 0, #5e5200 20%, #564801 50%, #5e5200 80%, #695d00);
  --alt-header-hover-text: var(--white);
  --alt-header-selected-background: var(--primary-color);
  --alt-header-size: unset;
  --alt-header-text-color: var(--primary-color);
  //--alt-header-menu-bottom-size: 4.3rem;

  --banners-grid-column: 3;

  --betslip-background: var(--secondary-color);

  --betItemData-background: unset;

  --bonus-btn--border: 1px solid transparent;

  --buttons-background-color: var(--primary-color);

  --background-color-modal-message: var(--primary-color);
  --background-color-button: var(--yellow-color);
  --background-color-modal-login: var(--primary-color);
  --color-more: var(--yellow-color);
  --background-color-notice: var(--primary-color);

  //--default-item-background: var(--secondary-color);
  //--default-item-border-color: unset;
  --default-item-background: linear-gradient(180deg, #2d2d2d, #1b1b1b);
  --default-item-border-color: linear-gradient(180deg, #2d2d2d, #1b1b1b);
  --default-item-hover: var(var(--yellow-color)) --default-item-select: linear-gradient(180deg, #2d2d2d, #1b1b1b);
  --default-item-border-radius: 0rem;

  --event-item-border-color: unset;
  --event-list-gap: 1.3rem;
  --event-list-gradient: var(--primary-color);

  --gap-layoutPages: unset;

  --hightlight-background: unset;
  --hightlight-border: unset;

  --main-menu-display: flex;

  --navigation-sports-game-number-color: unset;
  --navigation-sports-gradient: unset;
  --navigation-sports-item-color: var(--secondary-color);

  --primaryMarkets-text-color: #707478;
  --primaryMarkets-label-item-background: unset;

  --overlay-display: none;

  --times-gap: 1rem;

  --depositBonusItemBorder: 3px solid var(--yellow-color);
  --depositBonusTitleDescTextColor: var(--yellow-color);
}

html {
  font-size: 62.5%; /* 62.5% */
}

body {
  max-width: 100vw;
  overflow-x: hidden;
  // overflow-y: hidden;
  //font-family: "Rubik", sans-serif;
  font-family: "Malgun Gothic";
  //font-family: gulim, sans-serif;
  font-size: 1.4rem;
  line-height: 20px;
  text-rendering: optimizelegibility;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;

  background: var(--body-color);
  color: var(--black-color);

  padding: 0;
  margin: 0;
  position: relative;
}

a {
  text-decoration: none;
  margin: 0;
  color: var(--white);
}

.grecaptcha-badge {
  display: none !important;
}

.toast-custom {
  background: var(--primary-color) !important;
  height: 200px;

  div {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;

    svg {
      font-size: 6rem;
      color: var(--white);
    }

    span {
      display: flex;
      flex-direction: column;
      color: var(--white);
      line-height: 50px;
    }

    button {
      background: var(--yellow-color);
      border: none;
      border-radius: 5px;

      color: var(--color-button);
      font-size: 16px;
      margin-top: 1rem;
      padding: 5px;
      text-transform: uppercase;
      transition: all 0.5s ease;
      width: 100%;

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }

  // span {
  //   display: flex;
  //   flex-direction: column;
  //   color: var(--white);

  //   button {
  //     background: var(--subheader);
  //     border: none;
  //     border-radius: 5px;
  //     color: var(--white);
  //     font-size: 16px;
  //     margin-top: 1rem;
  //     padding: 5px;
  //     text-transform: uppercase;
  //     transition: all 0.5s ease;
  //     width: 100%;

  //     &:hover {
  //       opacity: 0.8;
  //       cursor: pointer;
  //     }
  //   }
  // }
}

.layoutPages {
  // display: grid;
  // grid-template-columns: 13% 70% 17%;
  display: flex;
  align-items: flex-start;
  // gap: .5rem;
  height: 100vh;
  padding-bottom: var(--alt-header-menu-bottom-size);
  padding-right: 1rem;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  gap: var(--gap-layoutPages);

  .indexPage {
    width: 100%;
  }

  .middle {
    //overflow-y: auto;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100%;
    width: 100%;
    //max-width: calc(100vw - 56.5rem);
    padding-bottom: var(--alt-header-size, 6.85rem);
  }

  .layoutRight {
    position: sticky;
    //height: calc(100vh - 9.8rem);
    //top: 7rem;
    height: 100%;
    z-index: 20;
    overflow-y: auto;
    max-width: 25rem;
    width: 100%;
    padding-bottom: var(--alt-header-size, 6.85rem);
  }

  .layoutLeft {
    position: sticky;
    //top: 7rem;
    //z-index: 20;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    margin-left: 0.5rem;
    max-width: 31rem;
    width: 100%;
    padding-bottom: var(--alt-header-size, 6.85rem);
  }
}

canvas {
  height: 100% !important;
  width: 100% !important;
}

@media only screen and (max-width: 1024px) {
  * {
    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 20px;
      border: transparent;
    }
  }

  .contentWrapper {
    height: calc(100vh - 12rem);

    .layoutPages {
      position: initial;
      padding-right: 0;
      flex-direction: column;
      //height: fit-content;
      grid-template-columns: 100%;

      .middle {
        max-width: none;
      }

      .layoutLeft {
        position: initial;
        display: none;
      }

      .layoutRight {
        height: initial;
        position: initial;
        display: none;
      }
    }
  }
}

@media (max-width: 1500px) {
  :root {
    --font-size-lg: 1.4rem;
    --font-size-md: 1.2rem;
    --font-size-sm: 0.6rem;

    .layoutPages {
      grid-template-columns: 100%;
    }

    .layoutPages {
      // grid-template-columns: 22% 56% 22%;
      grid-template-columns: 18% 59% 23%;
      padding-right: 0;
    }
  }
}
