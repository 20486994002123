.timeWrapper{
    display: flex;
    justify-content: center;

    .timer{
        font-family: "Montserrat";
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}