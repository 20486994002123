.accordionSportItem {
    margin-top: 0rem;

    .accordionTitleSportItem {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;

        // background-color: var(--pointbox);
        // border-radius: .5rem;

        color: white;
        cursor: pointer;

        font-size: 1.2rem;

        padding: 0.8rem 1rem;
            
        .rigth {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            img {
                width: 24px;
                height: auto;
            }

            svg {
                color: white;
                font-size: 1.5rem;
            }
        }

        &:hover{
            background: var(--primary-color);
        }
    }

    .sportITemChildreen {
        // background-color: var(--pointbox);
        border-radius: 0.5rem;

        margin: 0 2rem;

        > div {
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            background-color: var(--primary-color);
            // border-radius: 0.5rem;

            cursor: pointer;

            padding: 1rem;
            transition: all .35s ease;

            p {
                color: var(--white);
                font-size: 1.2rem;
                transition: all .35s ease;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
            }

            svg {
                display: none;
                color: var(--yellow-color);
                font-size: 2rem;
                transition: all .35s ease;
            }

            > div {
                display: flex;
                align-items: center;
                gap: 0;

                &:hover {
                    padding: 0;
                }
            }
        }

        div:hover {
            padding: 1rem;

            p {
                color: var(--yellow-color);
            }

            div {
                display: flex;
                gap: 1rem;

                > svg {
                    display: block;
                }
            }
        }
    }

    .sportITemChildreen {
        div:first-child {
            border-radius: 0.5rem 0.5rem 0 0;
        }

        div:last-child {
            border-radius: 0 0 0.5rem 0.5rem;
        }
    }

    .accordionLine {
        // border-bottom: 1px solid var(--multiborder);

        margin-top: 1rem;
        width: 100%;
    }
}
