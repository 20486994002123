.messageModal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99999;

  .container {
    max-width: 100%;
    width: 49vh;
    height: 42vh;
    margin: 0;
    border-radius: var(--border-radius);

    .content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background: var(--background-color-modal-message);
      border-radius: var(--border-radius);
      padding: 2rem 0;

      .image {
        //height: 100%;
        display: flex;
        align-items: center;
        height: 15%;
        width: 15%;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .message {
        //width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        font-size: 1.6rem;
        font-weight: 600;
        gap: 1.5rem;
        padding: 2rem;
        width: 90%;
        justify-content: center;

        > p {
          text-align: center;
        }
      }

      .control {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: fit-content;

        > button {
          border: 0;
          background: var(--background-color-button);
          color: var(--color-button);
          width: 15rem;
          height: 3rem;
          border-radius: var(--border-radius);
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .messageModal {
    .container {
      max-width: 100%;
      width: 80vw;
      height: 30vh;

      .content {
        padding: 2rem 0;

        .image {
          height: 15%;
          width: auto;

          img {
            object-fit: contain;
            height: 100%;
            width: 100%;
          }
        }

        .message {
          width: 90%;
          justify-content: center;
        }

        .control {
          // height: 100%
          height: fit-content;
        }
      }
    }
  }
}
