.navigationMobileHeading {
    display: flex;
    padding: 1.5rem 1rem;
    justify-content: space-between;

    .logoMenu {
      img {
        max-width: 15rem;
        height: auto;
        cursor: pointer;
      }
    }

    .iconClose {
      position: relative;
      display: flex;
      justify-content: flex-end;

      svg {
        // position: absolute;
        // top: -.5rem;
        // right: 0.5rem;
        z-index: 2;
        color: white;
        font-size: 3rem;
      }
    }
  }