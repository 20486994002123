.modalLogin {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 30;

  .container {
    position: relative;

    display: flex;
    flex-direction: column;

    background-color: var(--background-color-modal-login);
    border-radius: 5px;

    padding: 3rem;
    width: 30%;
    height: auto;

    .closeModalSvg {
      position: absolute;
      top: 0.5rem;
      right: 1rem;
      z-index: 2;

      background-color: white;
      border-radius: 50%;
      cursor: pointer;
      font-size: 2.5rem;
      padding: 0.5rem 0;
      margin-top: 0.5rem;
    }

    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5rem;

      img {
        max-width: 15rem;
        max-height: 5rem;
        object-fit: cover;
      }
    }

    h1 {
      color: var(--white);
      font-size: 3rem;

      text-align: center;
    }

    form {
      .inputItem {
        display: flex;
        align-items: center;
        gap: 1rem;

        button {
          position: relative;
          z-index: 1;

          display: inline-block;

          background: var(--background-color-button);
          border: 0;
          border-radius: 0.5rem;

          color: var(--color-button);
          cursor: pointer;

          font-size: 1.4rem;
          font-weight: 600;

          overflow: hidden;
          padding: 1rem;

          text-transform: capitalize;
          transition: all 0.3s;
          text-align: center;

          width: 35%;

          // &::before {
          //     position: absolute;
          //     content: "";
          //     top: 0;
          //     left: 0;
          //     height: 100%;
          //     width: 0px;
          //     transition: all 0.4s;
          // }

          // &:hover::before {
          //     width: 100%;
          // }

          span {
            z-index: 1;
            position: relative;
            transition: all 0.4s;
            display: block;
          }

          // &::before {
          //     background: linear-gradient(114.26deg, #ff6600 13.41%, #ff6600 87.24%);
          // }
        }
      }

      label {
        color: var(--white);
        font-size: 1.6rem;
      }

      input {
        width: 100%;
        font-size: 16px;
        padding: 1rem;
        border: 1px solid black;
        border-radius: 0.5rem;
        // margin-top: 1rem;
      }

      .inputRow{
        display: flex;
        flex-direction: row;
        gap: 1rem;
      }

      .inputControl {
        width: 100%;
        margin-bottom: 2rem;

        .input {
          width: 100%;
          display: flex;
          gap: 1rem;
        }

        .inputPassword {
          width: 100%;
          position: relative;

          .iconPasswordPrimary {
            position: absolute;
            right: 1rem;
            top: 1rem;
            color: #a1a1a1;
            font-size: 20px;
            z-index: 2;
            cursor: pointer;
          }

          .iconPassword {
            position: absolute;
            right: 1rem;
            top: 2.9rem;
            color: #a1a1a1;
            font-size: 20px;
            z-index: 2;
            cursor: pointer;
          }
        }

        a {
          justify-content: center;
          cursor: pointer;
          display: flex;
          font-size: 1.5rem;
        }

        .perfilPasswords {
          display: flex;
          align-items: flex-start;
          gap: 1rem;
        }

        .documents {
          display: flex;
          align-items: flex-start;
          gap: 1rem;

          .document {
            width: 100%;

            .inpot {
              min-height: 46px;
            }
            // z-index: 2;
          }
        }
      }

      .btns {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        width: 100%;

        margin-top: 1.5rem;

        button {
          position: relative;
          z-index: 1;

          display: inline-block;

          background: var(--background-color-button);
          border: 0;
          border-radius: 1rem;

          color: var(--color-button);
          cursor: pointer;

          font-size: 1.4rem;
          font-weight: 600;

          overflow: hidden;
          padding: 1rem 2rem;

          text-transform: capitalize;
          transition: all 0.3s;
          text-align: center;

          width: 100%;

          // &::before {
          //     position: absolute;
          //     content: "";
          //     top: 0;
          //     left: 0;
          //     height: 100%;
          //     width: 0px;
          //     transition: all 0.4s;
          // }

          // &:hover::before {
          //     width: 100%;
          // }

          span {
            z-index: 1;
            position: relative;
            transition: all 0.4s;
            display: block;
          }
        }

        // button:first-child {
        //     background: var(--button-two);

        //     &::before {
        //         background: linear-gradient(114.26deg, #f3486a 13.41%, #f3486a 87.24%);
        //     }
        // }

        // button:last-child {
        //     background: var(--button-one);

        //     &::before {
        //         background: linear-gradient(114.26deg, #ff6600 13.41%, #ff6600 87.24%);
        //     }
        // }
      }
    }

    .verificationSms {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .contentCode {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        gap: 1rem;

        .label {
          width: 100%;
          color: white;
          display: flex;
          justify-content: center;
          > p {
            font-size: 1.6rem;
          }
        }

        .input {
          width: 100%;
          align-self: center;
          display: flex;
          flex-direction: column;
          justify-content: center;

          > input {
            padding: 1rem;
            width: 100%;
            text-align: center;
          }
        }

        .resendArea {
          width: 100%;
          padding-bottom: 2rem;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .countDown {
            > p {
              color: white;
              font-size: 15px;
            }
          }
        }

        .btn {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .btnActive,
          .btnBlocked {
            position: relative;
            z-index: 1;

            display: inline-block;

            background: var(--yellow-color);
            border: 0;
            border-radius: 1rem;

            color: var(--color-button);
            cursor: pointer;

            font-size: 1.4rem;
            font-weight: 600;

            overflow: hidden;
            padding: 1rem 2rem;

            text-transform: capitalize;
            transition: all 0.3s;
            text-align: center;

            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .modalLogin {
    .container {
      overflow-x: auto;
      height: auto;
      margin: 1rem 0;
      width: 40%;

      .closeModalSvg {
        top: 0.5rem;
        right: 1rem;
      }

      form {
        .inputItem {
          button {
            font-size: 1.2rem;
            padding: 1.2rem;
            width: 50%;
          }
        }

        .inputControl {
          .perfilPasswords {
            flex-direction: column;
          }

          .documents {
            flex-direction: column;
          }
        }

        .btns {
          flex-direction: column;

          button {
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .modalLogin {
    z-index: 99999;

    .container {
      height: fit-content;
      overflow-x: auto;
      // height: 97%;
      width: 98%;
      padding: 1rem 1rem 3rem;

      h1 {
        font-size: var(--font-size-lg);
      }

      label {
        font-size: var(--font-size-md) !important;
      }

      div {
        font-size: var(--font-size-md);
      }
      span {
        font-size: var(--font-size-md);
      }

      input {
        font-size: var(--font-size-md);
      }

      .iconPassword {
        top: 1.9rem !important;
      }

      .image {
        margin-bottom: 2rem;
      }

      .content {
        overflow-x: auto;
        // height: 92%;

        .closeModalSvg {
          top: 0.5rem;
          right: 1rem;
        }
      }

      .btns {
        margin: 2rem 0;
      }
    }
  }
}
