.errorMessage {
  width: 100%;
  display: flex;
  align-items: center;
  color: red;
  font-size: 1.5rem;
  margin-top: 0.5rem;
  transition: all 0.35s ease;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif; 
  svg {
    font-size: 1.3rem;
    fill: red;
    margin-right: 0.5rem;
  }
}
