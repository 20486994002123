.alertModal {
  :global(.MuiPaper-root) {
    max-width: 100%;
    width: 80%;
    height: 40%;
    margin: 0;
    border-radius: 0.5rem;
    background: var(--primary-color);
    color: white;
  }

  .content {
    padding: 2.4rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header {
      // padding: 1rem;
      // border-bottom: 1px solid black;
      text-align: center;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;

      > h1 {
        font-size: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
      }

      > svg {
        cursor: pointer;
        font-size: 25px;
      }
    }

    .message {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 2rem;
      align-items: center;

      > h1,
      svg {
        text-align: center;
        font-size: 2rem;
      }

      svg {
        font-size: 4rem;
        color: orange;
      }
    }

    .controls {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;

      > button {
        font-size: 1.4rem;
        padding: 1rem 2rem;
        text-transform: uppercase;
        color: white;
        border: 0;
        border-radius: 0.5rem;
        cursor: pointer;
      }

      > button:first-child {
        background-color: var(--yellow-color);
        color: var(--color-button);
        // border: 1px solid black;
      }

      > button:last-child {
        background-color: var(--yellow-color);
        color: var(--color-button);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .alertModal {
    z-index: 99999 !important;

    :global(.MuiPaper-root) {
      height: 50%;
    }

    .content {
      gap: 2rem;

      .header{
        margin-bottom: 0;
      }
      .message {
        flex-direction: column;
        gap: 1rem;
      }
    }
  }
}
