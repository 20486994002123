.popupsModal {
    :global(.MuiPaper-root) {
        max-width: 100%;
        width: 100%;
        height: 100vh;
        margin: 0;
        border-radius: var(--border-radius);
        background: transparent;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // overflow: hidden;
    }

    .content {
        width: 100%;
        height: 100%;
        // display: flex;
        display: grid;
        grid-template-columns: repeat(4, 20%);
        grid-template-rows: 60%;
        // grid-auto-flow: dense;
        overflow: hidden;
        // flex-direction: row;
        // align-items: center;
        // justify-content: center;
        gap: 2rem;
        padding: 0rem 3rem;

        .popup {
            display: flex;
            flex-direction: column;
            background-color: white;
            // max-height: calc(100vh - 10rem);
            // height: calc(100vh - 300px);
            max-width: 400px;
            height: 42rem;

            .popupHeader {
                height: 50px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 1rem;
                background: var(--header);
                color: white;
                font-weight: 600;
                align-items: center;

                .announce,
                .notShow {
                    display: flex;
                    gap: 1rem;
                    font-size: 1.4rem;
                    align-items: center;

                    > span {
                        font-size: 12px;
                    }
                }

                .notShow {
                    cursor: pointer;
                }
            }

            .popupBody {
                height: auto;
                width: 100%;

                > img {
                    object-fit: fill;
                    height: 100%;
                    width: 100%;
                }
            }

            .popupFooter {
                background: var(--header);
                display: flex;
                flex-direction: row;
                justify-content: center;
                height: 63px;
                padding: 1rem;
                align-items: center;

                > button {
                    border: none;
                    background: var(--yellow-color);
                    cursor: pointer;
                    padding: 1rem;
                    border-radius: var(--border-radius);
                    font-weight: 600;
                }
            }
        }

        .emptyPopup {
            z-index: -1;
            pointer-events: none;
        }
    }
}

// @media only screen and (max-width: 1500px){
//     .popup{
//         width: calc(100vh - 400px);
//     }
// }

@media only screen and (max-width: 1150px) {
    .popupsModal {
        :global(.css-hz1bth-MuiDialog-container) {
            height: 100%;
        }

        .content {
            display: grid;
            grid-template-columns: none;
            justify-content: center;

            .popup {
                grid-area: 1 / 1;
                width: 400px;

                .popupBody {
                    max-width: 400px;
                    // width: 320px;
                    // height: 385px;
                    display: block;
                }

                div {
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .popupsModal {
        padding: 3rem 1rem;
        height: 100%;

        .content {
            //align-items: center;
            grid-template-rows: none;
            grid-auto-flow: dense;
            grid-template-columns: none;
            justify-content: center;

            .popup {
                height: 100%;
                width: 100%;
                max-height: 36rem;
                display: flex;
                flex-direction: column;
                background-color: white;
                max-width: 400px;
            }
        }
    }
}
