.modalTimeLive {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 20;
  
    .container {
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;

        background-color: var(--primary-color);
        border-radius: 5px;

        padding: 3rem;
        width: 25%;
        height: 400px;
        // bottom: 300px;

        .content{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}

@media only screen and (max-width: 1200px){
    .modalTimeLive{
        .container{
            width: 50%;
        }
    }
}

@media only screen and (max-width: 760px){
    .modalTimeLive{
        .container{
            width: 80%;
        }
    }
}