.footerMobile {
    position: relative;
    height: 5rem;
    z-index: 1;

    .footerMenu {
        position: fixed;
        // bottom: 0;
        // left: 0;
        // // z-index: 90;
        //z-index: 100001;
        //top: -2.5rem;
        bottom: 0;
        // z-index: 99999;

        display: flex;
        // padding: 8px 10px 9px;
        padding: 0 1rem;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        background-color: var(--header);
        width: 100%;
        height: 5rem;

        li {
            list-style: none;

            .dGrid {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 14px;
                transition: all 0.4s;

                span {
                    transition: all 0.4s;
                    color: var(--white);

                    svg {
                        color: var(--yellow-color);
                        font-size: 2rem;
                    }

                    img {
                        height: 3rem;
                        width: auto;
                    }
                }

                .texta {
                    margin-top: -2px;
                }
            }
        }

        .headerBartwo {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;

            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 2px dashed var(--yellow-color);
            // text-align: center;
            // line-height: 38px;

            // .bars {
            //     font-size: 19px;
            //     background: -webkit-linear-gradient(90deg, #d73c17 -20.51%, #ffa83c 135.9%);
            //     -webkit-background-clip: text;
            //     -webkit-text-fill-color: transparent;
            // }

            .numItems {
                position: absolute;
                display: flex;
                justify-content: center;
                top: -0.5rem;
                right: -0.5rem;
                color: var(--black);
                background-color: var(--yellow-color);
                border-radius: 2rem;
                padding: 0 0.5rem;
            }

            .cros {
                svg {
                    color: var(--yellow-color);
                    font-size: 2rem;
                }
            }
        }
    }
}
