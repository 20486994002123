.accordionHighlights {
  border-top: 1px solid white;

  .accordionTitle {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;

    background: var(--slidebox);

    border-bottom: 1px solid var(--white);

    color: white;
    cursor: pointer;
    width: 100%;
    font-size: 1.3rem;

    padding: 10px 10px 10px 10px;

    .nameIcon {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .rigth {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;

      img {
        width: 24px;
        height: auto;
      }

      svg {
        color: var(--yellow-color);
        width: 2.4rem;
        height: 2.6rem;
        padding: 0.4rem;
      }
    }

    svg {
      color: var(--yellow-color);
      font-size: 2.5rem;
    }
  }

  .accordionLine {
    border-bottom: 1px solid white;
    width: 100%;
  }
}
