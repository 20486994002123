.content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  padding: 0.8rem 1rem;
  gap: 2rem;
  cursor: pointer;
  font-size: var(--font-size-md);
  color: var(--white);
  cursor: pointer;
  border-bottom: 1px solid var(--white);
  background: var(--hightlight-background);
  border-color: var(--hightlight-border);

  .sportIcon {
    img {
      width: auto;
      height: 2.5rem;
    }
  }

  .participantsName {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 0.1rem;

    .date {
      font-size: calc(var(--font-size-md) - 0.15rem);
    }

    .participantContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      font-size: calc(var(--font-size-md) - 0.15rem);

      img {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  &:hover{
    background: var(--secondary-color);
  }
}
