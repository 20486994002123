.accordionSports {
    border-top: 1px solid white;

    .accordionTitle {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;

        background: var(--slidebox);

        color: white;
        cursor: pointer;

        font-size: 1.3rem;

        padding: 10px 10px 5px 10px;

        .rigth {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            img {
                width: 24px;
                height: auto;
            }

            svg {
                color: var(--yellow-color);
                width: 2.4rem;
                height: 2.6rem;
                padding: 0.4rem 0;
            }
        }

        svg {
            color: var(--yellow-color);
            font-size: 2.5rem;
        }
    }

    .accordionLine {
        border-bottom: 1px solid white;
        width: 100%;
    }
}
