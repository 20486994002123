.maintanceModal{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 99999;

    .container{
        height: 30rem;
        min-width: 30rem;
        background: black;
        padding: 1rem;
        border-radius: 99%;

        .content{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: white;
            gap: 2rem;

            >img{
                width: 80px;
            }

            >p{
                width: 250px;
                font-size: 15px;
                text-align: center;
            }
        }
    }
}
