.betItemData {
  padding-bottom: 1rem;
  text-align: left;
  position: relative;
  background: var(--betItemData-background);

  .lockItem {
    min-height: 78px;
    position: absolute;
    top: 0;
    background: red;
    width: 100%;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;

    .image {
      opacity: 1 !important;
    }
  }

  .leagueData {
    display: flex;

    .league {
      font-size: 1.2rem;
      width: 80%;
    }

    .buttonContainer {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      width: 20%;
      text-align: right;

      .closeButton {
        z-index: 999;
        cursor: pointer;
      }
    }
  }

  .marketData {
    display: flex;

    .market {
      color: var(--active-two);
      font-size: 1.2rem;

      width: 70%;
    }

    .price {
      color: var(--price-color);
      font-size: 1.2rem;

      width: 30%;
      text-align: right;
    }
  }

  span {
    margin: 6px 0;
  }

  .game {
    display: block;

    font-size: 1.2rem;
  }

  .divisor {
    border-bottom: 1px solid var(--multiborder);
    padding-top: 1rem;
  }
}

.betItemData:last-child {
  padding-bottom: 0;
}

@media screen and (max-width: 1024px) {
  .betItemData {
    span {
      margin: 0.1rem 0;
    }
  }
}
